import Image from 'next/image';
import { useRouter } from 'next/router';
import Button from '../src/components/CustomButtons/Button';
export default function NotFound() {
  const router = useRouter();
  const handleChange = () => {
    router.push('/');
  };
  return (
    <div className="center">
      <Image src="/error/404.svg" alt="me" width="350" height="350" />
      <Button color="theme" onClick={handleChange}>
        Home
      </Button>
    </div>
  );
}
